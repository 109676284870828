html {
  overflow-y: scroll;
}


.App-logo {
  height: 40vmin;
  pointer-events: none; 
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
 
a:-webkit-any-link {
  text-decoration: none;
   cursor: pointer;
}

a {
  text-decoration: "none";
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
.blinking-cursor {
  font-weight: 100;
  font-size: 30px;
  color: #64B64F;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}

.dos {
  color: "#64B64F";
  font-family: "VT323";
  white-space: "pre-line";
}

@keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: #64B64F;
  }
}

@-moz-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: #64B64F;
  }
}

@-webkit-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: #64B64F;
  }
}

@-ms-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: #64B64F;
  }
}

@-o-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: #64B64F;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
